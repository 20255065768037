export enum SiteTitle {
  account = 'APPLICATION.PAGE_TITLES.account',
  activationLink = 'APPLICATION.PAGE_TITLES.activationLink',
  buildings = 'APPLICATION.PAGE_TITLES.buildings',
  calculations = 'APPLICATION.PAGE_TITLES.calculations',
  confirmEmail = 'APPLICATION.PAGE_TITLES.confirmEmail',
  contact = 'APPLICATION.PAGE_TITLES.contact',
  carbonAssistant = 'APPLICATION.PAGE_TITLES.carbonAssistant',
  contracts = 'APPLICATION.PAGE_TITLES.contracts',
  cultivations = 'APPLICATION.PAGE_TITLES.cultivations',
  cultivationAssistant = 'APPLICATION.PAGE_TITLES.cultivationAssistant',
  documents = 'APPLICATION.PAGE_TITLES.documents',
  emissionCalculations = 'APPLICATION.PAGE_TITLES.emissionCalculations',
  employees = 'APPLICATION.PAGE_TITLES.employees',
  employeeSalaryReport = 'APPLICATION.PAGE_TITLES.employeesSalaryReport',
  meansOfProductionReport = 'APPLICATION.PAGE_TITLES.meansOfProductionReport',
  farm = 'APPLICATION.PAGE_TITLES.farm',
  fertilizersDb = 'APPLICATION.PAGE_TITLES.fertilizersDb',
  fertilizerStockNeeds = 'APPLICATION.PAGE_TITLES.fertilizerStockNeeds',
  forgottenPassword = 'APPLICATION.PAGE_TITLES.forgottenPassword',
  login = 'APPLICATION.PAGE_TITLES.login',
  machines = 'APPLICATION.PAGE_TITLES.machines',
  messages = 'APPLICATION.PAGE_TITLES.messages',
  monitoring = 'APPLICATION.PAGE_TITLES.monitoring',
  myFields = 'APPLICATION.PAGE_TITLES.myFields',
  observations = 'APPLICATION.PAGE_TITLES.observations',
  rucip = 'APPLICATION.PAGE_TITLES.rucip',
  partners = 'APPLICATION.PAGE_TITLES.partners',
  pesticides = 'APPLICATION.PAGE_TITLES.pesticides',
  register = 'APPLICATION.PAGE_TITLES.register',
  treatments = 'APPLICATION.PAGE_TITLES.treatments',
  reportCropRotation = 'APPLICATION.PAGE_TITLES.reportCropRotation',
  reportCarbonEmission = 'APPLICATION.PAGE_TITLES.reportCarbonEmission',
  reportEmployeeManualHarvest = 'APPLICATION.PAGE_TITLES.reportEmployeeManualHarvest',
  reportPlotManualHarvest = 'APPLICATION.PAGE_TITLES.reportPlotManualHarvest',
  reportTreatmentProductionCost = 'APPLICATION.PAGE_TITLES.reportTreatmentProductionCost',
  reportSummaryManualHarvest = 'APPLICATION.PAGE_TITLES.reportSummaryManualHarvest',
  reportVehicle = 'APPLICATION.PAGE_TITLES.reportVehicle',
  reportFuel = 'APPLICATION.PAGE_TITLES.reportFuel',
  reportResourceUsage = 'APPLICATION.PAGE_TITLES.reportResourceUsage',
  reports = 'APPLICATION.PAGE_TITLES.reports',
  resetPassword = 'APPLICATION.PAGE_TITLES.resetPassword',
  satelliteImages = 'APPLICATION.PAGE_TITLES.satelliteImages',
  settings = 'APPLICATION.PAGE_TITLES.settings',
  soilAnalysis = 'APPLICATION.PAGE_TITLES.soilAnalysis',
  stockOffers = 'APPLICATION.PAGE_TITLES.stockOffers',
  suppliers = 'APPLICATION.PAGE_TITLES.suppliers',
  warehouses = 'APPLICATION.PAGE_TITLES.warehouses',
  warehousesProductsList = 'APPLICATION.PAGE_TITLES.warehousesProductsList',
  warehousesProductStocks = 'APPLICATION.PAGE_TITLES.warehousesProductStocks',
  weatherStations = 'APPLICATION.PAGE_TITLES.weatherStations',
  storageSensors = 'APPLICATION.PAGE_TITLES.storageSensors',
  devices = 'APPLICATION.PAGE_TITLES.devices',
  loyaltyProgram = 'APPLICATION.PAGE_TITLES.loyaltyProgram',
  purchasePlaces = 'APPLICATION.PAGE_TITLES.purchasePlaces',
  passport = 'APPLICATION.PAGE_TITLES.passport'
}
