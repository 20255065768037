import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'noData'
})
export class NoDataPipe implements PipeTransform {
  constructor(private readonly translationService: TranslateService) {}

  transform(value: unknown): unknown {
    if (!value) {
      return this.translationService.instant('APPLICATION.LIST.noData');
    }

    return value;
  }
}
