import { Injectable } from '@angular/core';
import { TranslateService } from '../translation';

@Injectable({
  providedIn: 'root'
})
export class DownloadCsvService {
  downloadFile(data, fields, filename = 'data', translationService: TranslateService | null = null, translationKey = '') {
    const csvData = this.convertToCSV(data, fields, translationService, translationKey);

    const blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;'
    });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      downloadLink.setAttribute('target', '_blank');
    }
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', filename + '.csv');
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  convertToCSV(objArray, headerList, translationService: TranslateService | null, translationKey: string) {
    const separator = ';';
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    const row = !translationService
      ? headerList.join(separator)
      : headerList.map(element => translationService.translate(`${translationKey}.${element}`)).join(separator);
    str += row + '\r\n';
    array.forEach(element => {
      const lineArray = [];
      headerList.forEach(key => {
        let value = element[key];

        if (typeof value === 'number') {
          value = value.toFixed(2);
        }

        lineArray.push(value);
      });
      str += lineArray.join(separator) + '\r\n';
    });
    return str;
  }
}
