<div
  ngbDropdown
  [placement]="'top left'">
  <a
    class="nav-link position-relative"
    id="dropdownBasic3"
    ngbDropdownToggle>
    <i class="ph ph-dots-three"></i>
  </a>
  <div
    ngbDropdownMenu
    aria-labelledby="dropdownBasic3"
    class="text-left">
    <a
      class="dropdown-item p-2 d-flex align-items-center"
      routerLink="/settings/security"
      *ngIf="hasAccess('Organization.Settings.Permissions', 'List')">
      <i class="ph ph-gear mr-1"></i>
      <span>{{ 'APPLICATION.NAVBAR.security' | translate }}</span>
    </a>
    <a
      class="dropdown-item p-2 d-flex align-items-center"
      routerLink="/settings/notifications"
      *ngIf="hasAccess('Organization.Settings.Notifications', 'Read')">
      <i class="ph ph-chats-circle mr-1"></i>
      <span>{{ 'APPLICATION.NAVBAR.notifications' | translate }}</span>
    </a>
    <a
      class="dropdown-item p-2 d-flex align-items-center"
      href="javascript:;"
      (click)="logoutClick($event)">
      <i class="ph ph-power mr-1"></i>
      <span>{{ 'APPLICATION.NAVBAR.logout' | translate }}</span>
    </a>
  </div>
</div>
