import { RouteGroup } from '@farm-portal/core/layout/sidebar/sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteGroup[] = [
  {
    groupTitle: 'APPLICATION.MENU.groundsAndCultivations:groupTitle',
    components: [],
    routeGroup: [
      {
        path: '/my-fields',
        title: 'APPLICATION.MENU.grounds',
        icon: 'ph-duotone ph-farm',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Grounds']
      },
      {
        path: '/cultivation-assistant',
        title: 'APPLICATION.MENU.cultivationAssistant',
        icon: 'ph-duotone ph-plant',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Grounds']
      },
      {
        path: '/treatments',
        title: 'APPLICATION.MENU.treatments',
        icon: 'ph-duotone ph-clipboard-text',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Farm.Manage']
      },
      {
        path: '/soil-analysis',
        title: 'APPLICATION.MENU.soilAnalysis',
        icon: 'ph-duotone ph-flask',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.SoilAnalysis']
      },
      {
        path: '/satellite-images',
        title: 'APPLICATION.MENU.satelliteImages',
        icon: 'ph-duotone ph-planet',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.SatelliteImages']
      },
      {
        path: '/observations',
        title: 'APPLICATION.MENU.observations',
        icon: 'ph-duotone ph-eye',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Observations.Read']
      },
      {
        path: '',
        title: 'APPLICATION.MENU.carbonAssistance',
        icon: 'ph-duotone ph-calculator',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        components: ['Organization.EmissionCalculations'],
        submenu: [
          {
            path: '/carbon-assistant',
            title: 'APPLICATION.MENU.CARBON_ASSISTANCE.carbonAssistant',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          },
          {
            path: '/emission-calculations',
            title: 'APPLICATION.MENU.CARBON_ASSISTANCE.emissionCalculations',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          }
        ]
      }
    ]
  },
  {
    groupTitle: 'APPLICATION.MENU.farm:groupTitle',
    components: [],
    routeGroup: [
      {
        path: '/farm',
        title: 'APPLICATION.MENU.farm',
        icon: 'ph-duotone ph-garage',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Farm.Manage']
      },
      {
        path: '',
        title: 'APPLICATION.MENU.resources',
        icon: 'ph-duotone ph-factory',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/machines',
            title: 'APPLICATION.MENU.machines',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Machine']
          },
          {
            path: '/employees',
            title: 'APPLICATION.MENU.employees',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Employees']
          },
          {
            path: '/buildings',
            title: 'APPLICATION.MENU.buildings',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Buildings']
          }
        ]
      },
      {
        path: '/documents',
        title: 'APPLICATION.MENU.documents',
        icon: 'ph-duotone ph-file-text',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Documents']
      },
      {
        path: '/report',
        title: 'APPLICATION.MENU.reports',
        icon: 'ph-duotone ph-trend-up',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        components: ['Organization.Farm.Manage'],
        submenu: []
      },
      {
        path: '',
        title: 'APPLICATION.MENU.warehouses',
        icon: 'ph-duotone ph-warehouse',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/warehouses/products/stocks',
            title: 'APPLICATION.MENU.WAREHOUSES.warehouseStocks',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          },
          {
            path: '/warehouses/products/list',
            title: 'APPLICATION.MENU.WAREHOUSES.products',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.WarehouseProducts']
          }
        ],
        components: ['Organization.Warehouses']
      },
      {
        path: '/fertilizer-stock-needs',
        title: 'APPLICATION.MENU.fertilizerStockNeeds',
        icon: 'ph-duotone ph-grains',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Farm.Manage']
      },
      {
        path: '/messages',
        title: 'APPLICATION.MENU.messages',
        icon: 'ph-duotone ph-chat-centered-text',
        class: 'd-none',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Messages']
      },
      {
        path: '',
        title: 'APPLICATION.MENU.trade',
        icon: 'ph-duotone ph-briefcase',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        components: [],
        submenu: [
          {
            path: '/suppliers',
            title: 'APPLICATION.MENU.suppliers',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.ManageOrganizations.Suppliers']
          },
          {
            path: '/contracts',
            title: 'APPLICATION.MENU.contracts',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Contracts']
          },
          {
            path: '/stock-offers',
            title: 'APPLICATION.MENU.WAREHOUSES.stockOffers',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.StockOffers']
          },
          {
            path: '/partners',
            title: 'APPLICATION.MENU.partners',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            components: ['Organization.Farm.Manage.Partners'],
            submenu: []
          },
          {
            path: '/rucip',
            title: 'APPLICATION.MENU.rucip',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Partners.PFZ']
          },
          {
            path: '/purchase-places',
            title: 'APPLICATION.MENU.purchasePlaces',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Partners.PFZ']
          },
          {
            path: '/purchase-places-quotes',
            title: 'APPLICATION.MENU.purchasePlacesQuotes',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Partners.PFZ']
          },
          {
            path: '/passport',
            title: 'APPLICATION.MENU.foodPassport',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          }
        ]
      }
    ]
  },
  {
    groupTitle: 'APPLICATION.MENU.tools:groupTitle',
    components: [],
    routeGroup: [
      {
        path: '/vehtel-monitoring',
        title: 'APPLICATION.MENU.vehtelMonitoring',
        icon: 'ph-duotone ph-gauge',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Machine.Monitoring']
      },
      {
        path: '',
        title: 'APPLICATION.MENU.sensors',
        icon: 'ph-duotone ph-flag-banner',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/weatherstations',
            title: 'APPLICATION.MENU.weatherStationsData',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.WeatherStation.Configuration']
          },
          {
            path: `/storageSensors`,
            title: 'APPLICATION.MENU.storageSensors',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.StorageSensor']
          },
          {
            path: `/devices`,
            title: 'APPLICATION.MENU.gps',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: []
          }
        ]
      },
      {
        path: '',
        title: 'APPLICATION.MENU.databases',
        icon: 'ph-duotone ph-database',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/fertilizers-db',
            title: 'APPLICATION.MENU.DATABASE.fertilizersDb',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          },
          {
            path: '/pesticides',
            title: 'APPLICATION.MENU.DATABASE.pesticides',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Global.Settings.Pesticides']
          }
        ]
      },
      {
        path: '/loyalty',
        title: 'APPLICATION.MENU.loyaltyProgram',
        icon: 'ph-duotone ph-crown',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/shop-offers',
        title: 'APPLICATION.MENU.shopOffers',
        icon: 'ph-duotone ph-shopping-cart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      }
    ]
  }
];
