import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeFromSeconds' })
export class TimeFromSecondsPipe implements PipeTransform {
  transform(seconds: number) {
    if (!seconds) {
      return null;
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }
}
