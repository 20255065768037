<lib-form
  [style]="'full'"
  [formGroup]="formGroup"
  [isEditMode]="true"
  [displayFormButtons]="false">
  <app-input-ng-select
    [inputControl]="getControl('userOrganization')"
    [options]="userOrganizations$ | async"
    [clearable]="false"
    bindLabel="name"
    searchByField="name"
    [bindValue]="null"></app-input-ng-select>
</lib-form>
