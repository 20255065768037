import { Injectable } from '@angular/core';
import { ApiUrlProvider, OrganizationProvider, LoginDataProvider } from '@as/api';
import { Router } from '@angular/router';
import { AppConfigurationService, NotificationService } from 'farmcloud-core';
import { AuthStoreService } from '@farm-portal/core/auth/services/auth-store.service';
import { AUTH_PARTNERS } from '@farm-portal/core/auth/models/auth-partners.const';
const FARM_PORTAL_TOKEN = 'farmportal';

@Injectable({
  providedIn: 'root'
})
export class ApiDataProviderService implements ApiUrlProvider, OrganizationProvider, LoginDataProvider {
  constructor(
    private readonly configService: AppConfigurationService,
    private readonly authService: AuthStoreService,
    private readonly router: Router,
    private readonly notificationService: NotificationService
  ) {}

  get groupClaims(): string[] {
    return this.authService.getUserClaims;
  }

  public organizationId(): string {
    return this.authService.getUserOrganizationId;
  }

  public apiUrl(): string {
    const value = this.configService.configuration.api_url;
    if (!value) {
      throw new Error('Missing API url in configuration');
    }

    return value;
  }

  public accessToken(): string | null {
    return this.authService.getAccessToken;
  }

  public setAccessToken(token: string): void {
    this.authService.setAccessToken(token);
  }

  public refreshToken(): string | null {
    return this.authService.getRefreshToken;
  }

  public setRefreshToken(token: string): void {
    this.authService.setRefreshToken(token);
  }

  public navigateToLogout(): void {
    this.handleNavigate().then(() => {
      this.notificationService.info(`AUTH.logoutError:message`);
    });
  }

  private handleNavigate() {
    const partner = AUTH_PARTNERS.find(partner => this.hasAccess(partner.permissionComponent));
    const partnerId = partner ? partner.partnerId : undefined;
    return this.router.navigate(['auth', 'login'], { queryParams: { partnerId: partnerId } });
  }

  private hasAccess(componentKey: string): boolean {
    return this.groupClaims.some(claim => {
      return claim.startsWith(componentKey);
    });
  }

  public applicationToken(): string {
    return FARM_PORTAL_TOKEN;
  }

  public clearUserData(): void {
    this.authService.updateUserNotLogged();
  }
}
