import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { scrollToInvalidInput } from '@farm-portal/shared/functions/scroll-to-invalid-input';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'lib-form',
  templateUrl: './form.component.html'
})
export class FormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public headerTranslationKey: string;
  @Input() public formGroup: UntypedFormGroup;
  @Input() public isEditMode: boolean | null;
  @Input() public displayFormButtons = true;
  @Input() public isSubmitDisabled = false;
  @Input() public style: 'full' | 'half' = 'half';
  @Input() public isOneWayForm = false;

  @Output() public navigateBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onCancelForm: EventEmitter<void> = new EventEmitter<void>();
  @Output() public formSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() public editModeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public onInvalidForm: EventEmitter<void> = new EventEmitter<void>();

  public formStyle: string = FormComponent.HalfStyle;

  private fieldsEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private fieldsEnabledChangedSubscription: Subscription;
  private initialEditMode: boolean | null;
  private scrollToInvalidInputTimeout: NodeJS.Timeout | null;
  private static readonly HalfStyle = 'col-12 col-md-8 col-xl-5 form-col';
  private static readonly FullStyle = 'col-12';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public ngOnInit() {
    this.initialEditMode = this.isEditMode;
    this.fieldsEnabled$.next(this.initialEditMode);

    this.fieldsEnabledChangedSubscription = this.fieldsEnabled$.subscribe(fieldsEnabled => {
      this.checkAndToggleForm(fieldsEnabled);
    });
  }

  public ngOnDestroy(): void {
    this.fieldsEnabledChangedSubscription?.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.style) {
      this.updateFormStyle();
    }
  }

  public isEditVisible() {
    if (this.isEditMode === null || this.isEditMode === undefined) {
      return false;
    }

    return !this.isEditMode;
  }

  public checkFields() {
    if (this.isEditMode === null || this.isEditMode === undefined) {
      this.fieldsEnabled$.next(true);
      return;
    }

    this.fieldsEnabled$.next(this.isEditMode);
  }

  public onEditClick() {
    this.isEditMode = !this.isEditMode;
    this.checkFields();
  }

  public onCancel() {
    if (this.initialEditMode) {
      this.navigateBack.emit();
      return;
    }

    if (this.isEditMode) {
      this.isEditMode = false;
      this.fieldsEnabled$.next(this.isEditMode);
      this.onCancelForm.emit();
      return;
    }

    this.navigateBack.emit();
  }

  public onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      this.onInvalidForm.emit();
      this.setScrollToInvalidInputTimeout();
      return;
    }

    if (this.isEditMode && !this.isOneWayForm) {
      this.isEditMode = false;
      this.fieldsEnabled$.next(this.isEditMode);
    }

    this.formSubmitted.next();
  }

  private checkAndToggleForm(fieldsEnabled: boolean) {
    if (fieldsEnabled) {
      this.formGroup.enable({ emitEvent: false });
      this.editModeChanged.emit(true);
    } else {
      this.formGroup.disable({ emitEvent: false });
      this.editModeChanged.emit(false);
    }
  }

  private setScrollToInvalidInputTimeout() {
    if (this.scrollToInvalidInputTimeout !== null) {
      clearTimeout(this.scrollToInvalidInputTimeout);
      this.scrollToInvalidInputTimeout = null;
    }
    this.scrollToInvalidInputTimeout = scrollToInvalidInput(this.document);
  }

  private updateFormStyle() {
    if (this.style == 'half') {
      this.formStyle = FormComponent.HalfStyle;
      return;
    }

    if (this.style == 'full') {
      this.formStyle = FormComponent.FullStyle;
      return;
    }

    throw new Error(`${this.style} is not supported`);
  }
}
