import { DecimalPipe } from '@angular/common';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'nanDecimal'
})
export class NanDecimalPipe extends DecimalPipe {
  transform(value: null, digitsInfo?: string, locale?: string): null;
  transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  transform(value: number | string | null, digitsInfo?: string, locale?: string): string | null {
    if (value === null || isNaN(Number(value))) {
      return null;
    }

    return super.transform(value, digitsInfo, locale);
  }
}
