import { Component } from '@angular/core';
import { AuthStoreService } from '@farm-portal/core/auth/services/auth-store.service';

@Component({
  selector: 'base-auth',
  templateUrl: './base-auth.component.html',
  styleUrls: ['./base-auth.component.scss']
})
export class BaseAuthComponent {
  constructor(private baseAuthStoreService: AuthStoreService) {}

  public hasAccess(componentKey: string, permissionKey: string): boolean {
    const searchPermission = this.baseAuthStoreService.getUserClaims;
    if (searchPermission !== null) {
      const fullPermission = componentKey + '.' + permissionKey;
      return searchPermission.some(x => x === fullPermission);
    }
    return false;
  }
}
