import { Component, OnInit } from '@angular/core';

import { InputBaseComponent } from '../input.base';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-input-phone-number',
  templateUrl: './input-phone-number.component.html',
  styleUrls: ['./../input.base.scss']
})
export class InputPhoneNumberComponent extends InputBaseComponent implements OnInit {
  public phoneNoMask = ['+', '4', '8', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

  ngOnInit() {
    this.setControlValidation();
  }

  private setControlValidation(): void {
    const regex = '^\\+48 [0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9]';
    const phoneValidator = Validators.pattern(regex);
    const validator = this.inputControl.validator;
    if (validator === null) {
      this.inputControl.setValidators([phoneValidator]);
      return;
    }
    this.inputControl.setValidators([validator, phoneValidator]);
  }
}
