import { Component, EventEmitter, Output } from '@angular/core';
import { AuthStoreService } from '@farm-portal/core/auth/services/auth-store.service';
import { BaseAuthComponent } from '@farm-portal/modules/authorization/components/base-auth/base-auth.component';

@Component({
  selector: 'app-user-nav-item',
  templateUrl: './user-nav-item.component.html',
  styleUrls: ['./user-nav-item.component.scss']
})
export class UserNavItemComponent extends BaseAuthComponent {
  @Output()
  logout: EventEmitter<void> = new EventEmitter();

  constructor(baseAuthStoreService: AuthStoreService) {
    super(baseAuthStoreService);
  }

  public logoutClick(event) {
    this.logout.emit();
    event.stopPropagation();
  }
}
