export enum LegalFormCategoryEnum {
  NaturalPersonCarryingOutAnAgriculturalActivity = 'NaturalPersonCarryingOutAnAgriculturalActivity',
  SoleTrader = 'SoleTrader',
  CivilLawPartnership = 'CivilLawPartnership',
  LimitedLiabilityCompany = 'LimitedLiabilityCompany',
  JoinStockCompany = 'JoinStockCompany',
  GeneralPartnership = 'GeneralPartnership',
  Partnership = 'Partnership',
  LimitedPartnership = 'LimitedPartnership',
  LimitedJointStockPartnership = 'LimitedJointStockPartnership'
}
